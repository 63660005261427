import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getPanelById, getTimeByPanelId } from '../../../redux/selectors/analyzer.selector';
import { useDispatch, useSelector } from 'react-redux';
import { updateSessionPanelPartial } from '../../../redux/modules/analyzer.sessions.panel.update.module';
import { XAxisChart } from '../signalChart/XAxisChart';
import { CursorInput } from '../cursorInput/CursorInput';
import { formatDateWithMicrosecondsAndOffset, unixNanoToDate } from '../../helper/time';
import analyzerMessages from '../../../messages/analyzer.messages';
import { Tooltip } from '../../_tools/tooltip/TooltipV2';
import { withNewCursorUnixNano } from '../../helper/panelTime';
import { openModal } from '../../../redux/modules/modals.module';
import { ANALYZER_ADD_SIGNAL } from '../../../redux/constants/modals.constants';

export const PanelFooter: React.FC<{ panelId: number }> = React.memo(({ panelId }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const panel = useSelector((state) => getPanelById(state, panelId));
  const panelTime = useSelector((state) => getTimeByPanelId(state, panelId));

  const handCursorTimeChange = useCallback(
    (cursorUnixNano: number) => {
      if (cursorUnixNano === panelTime.cursorUnixNano) return;
      const selectedDate = panel?.rangeFrom ? new Date(panel.rangeFrom) : new Date();

      const newTime = withNewCursorUnixNano(panelTime, cursorUnixNano);
      const cursorTime = unixNanoToDate(panelTime.cursorUnixNano);

      const newDate = new Date(
        selectedDate.getTime() +
          (unixNanoToDate(newTime.cursorUnixNano).getTime() - cursorTime.getTime()),
      );
      dispatch(
        updateSessionPanelPartial(panelId, {
          rangeFrom: formatDateWithMicrosecondsAndOffset(newDate),
          status: 'updated',
          time: newTime,
        }),
      );
    },
    [dispatch, panel.rangeFrom, panelId, panelTime],
  );
  const onAddSignal = useCallback(() => {
    dispatch(openModal(ANALYZER_ADD_SIGNAL, { panelId }));
  }, [dispatch, panelId]);

  return (
    <>
      <div className='row-signal-footer col--1'>
        <div className='row-signal-input  add-signal-input'>
          <button className='button light-grey-white' onClick={onAddSignal}>
            <span className='fa fa-plus' />
            <FormattedMessage defaultMessage='Add Signal' id='analyzer.modals.add_signal' />
          </button>
        </div>
        <div className='row-signal-chart'>
          <XAxisChart panelId={panelId} />
        </div>
        <Tooltip
          position='top'
          data={{
            [formatMessage(analyzerMessages.cursorTime)]: formatMessage(
              analyzerMessages.cursorTimeDescription,
            ),
          }}
        >
          <div className='cursor-time-container'>
            <div className='cursor-time-label'>{formatMessage(analyzerMessages.cursorTime)} </div>
            <CursorInput
              cursorUnixNano={panelTime.cursorUnixNano}
              setCursorUnixNano={handCursorTimeChange}
              key={`${panelId}-cursor-time`}
            />
          </div>
        </Tooltip>
      </div>
    </>
  );
});

"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { INITIAL_SETTINGS, INITIAL_TOOL } from "./type/panelTool";
import { calculatePanelTime } from "../components/helper/panelTime";
import { formatDateWithMicrosecondsAndOffset } from "../components/helper/time";
import {
  INITIAL_COLOR,
  INITIAL_SIGNAL_HEIGHT_BOOL,
  INITIAL_SIGNAL_HEIGHT_STRING,
  INITIAL_SIGNAL_HEIGHT,
  MIN_RANGE_STEP_MS
} from "../../../common/constants/analyzer";
export const getDefaultSignalHeight = (signalType) => {
  switch (signalType) {
    case "bool":
      return INITIAL_SIGNAL_HEIGHT_BOOL;
    case "string":
      return INITIAL_SIGNAL_HEIGHT_STRING;
    default:
      return INITIAL_SIGNAL_HEIGHT;
  }
};
export const getDefaultChartType = (signal) => {
  if (signal.type === "bool")
    return "area";
  return "line";
};
const getDefaultPanelSignalProps = (signal) => ({
  color: INITIAL_COLOR,
  interpolationType: "stepAfter",
  height: getDefaultSignalHeight(signal.type),
  chartType: getDefaultChartType(signal)
});
let currentNegativeId = -1;
const generateNegativeId = () => currentNegativeId--;
export const createPanelSignal = (panelId, order, signal, overrides = {}) => __spreadValues(__spreadProps(__spreadValues({
  id: generateNegativeId()
}, getDefaultPanelSignalProps(signal)), {
  datapointUuid: signal.uuid,
  panelId,
  order,
  signal: __spreadProps(__spreadValues({}, signal), {
    panelId
  }),
  addons: []
}), overrides);
export const createDefaultAnomalyPanels = (anomaly) => {
  const panelAnomalyNames = ["Anomaly", "Reference"];
  let count = 1;
  return panelAnomalyNames.map((panelName) => ({
    id: count++,
    name: panelName,
    settings: INITIAL_SETTINGS,
    tool: INITIAL_TOOL,
    time: panelName === "Reference" ? calculatePanelTime(new Date(anomaly.referenceFrom), anomaly.rangeDeltaMs) : calculatePanelTime(new Date(anomaly.anomalyFrom), anomaly.rangeDeltaMs),
    rangeFrom: panelName === "Reference" ? anomaly.referenceFrom : anomaly.anomalyFrom,
    rangeDeltaMs: anomaly.rangeDeltaMs,
    archived: anomaly.archived,
    created_at: anomaly.created_at,
    updated_at: anomaly.updated_at,
    isPanelAnomaly: true
  }));
};
export const createAdditionalAnomalyPanel = (attr) => {
  const now = /* @__PURE__ */ new Date();
  const formateTime = formatDateWithMicrosecondsAndOffset(now);
  return __spreadValues({
    settings: INITIAL_SETTINGS,
    tool: INITIAL_TOOL,
    time: calculatePanelTime(now, MIN_RANGE_STEP_MS),
    rangeFrom: formateTime,
    rangeDeltaMs: MIN_RANGE_STEP_MS,
    created_at: formateTime,
    updated_at: formateTime,
    archived: false,
    isPanelAnomaly: true
  }, attr);
};

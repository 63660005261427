import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ExtraButtons from '../../common/tabsBar/extraButtons/ExtraButtons';
import { closeModal, openModal } from '../../../redux/modules/modals.module';
import {
  ANALYZER_SESSION_PANEL_EDIT,
  CONFIRMATION,
} from '../../../redux/constants/modals.constants';
import { deleteSessionPanel } from '../../../redux/modules/analyzer.sessions.panel.delete.module';
import { createSessionPanel } from '../../../redux/modules/analyzer.sessions.panel.create.module';
import {
  updateSessionPanel,
  cloneAnomalyPanel,
  deleteAnomalyPanel,
} from '../../../redux/modules/analyzer.sessions.panel.update.module';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import commonMessages from '../../../messages/common.messages';
import analyzerMessages from '../../../messages/analyzer.messages';
import { usePanelExcelExport } from './Helper';
import { Panel } from '../../../../../common/validation/analyzer.validation';

interface PanelActionsProps extends WrappedComponentProps {
  status: string;
  panel: { isPanelAnomaly: boolean } & Panel;
}
const ActionButtons: React.FC<PanelActionsProps> = ({ intl, status, panel }) => {
  const dispatch = useDispatch();
  const exportToExcel = usePanelExcelExport(panel);

  const extraButtons = useMemo(
    () => [
      {
        isActive: true,
        onClick: () =>
          dispatch(
            openModal(ANALYZER_SESSION_PANEL_EDIT, {
              id: panel.sessionId,
              panelId: panel.id,
              name: panel.name,
            }),
          ),
        color: 'light-grey-white',
        icon: 'fa fa-pencil',
        title: intl.formatMessage(commonMessages.edit),
        description: intl.formatMessage(analyzerMessages.editPanelDescription),
        showTooltip: true,
      },
      {
        icon: 'fa fa-floppy-o',
        isActive: true,
        color: status === 'updated' ? 'light-green-white' : 'light-grey-white',
        onClick: () => dispatch(updateSessionPanel(panel.sessionId, panel.id, {})),
        title: intl.formatMessage(commonMessages.save),
        description: intl.formatMessage(analyzerMessages.savePanelDescription),
        showTooltip: true,
      },
      {
        icon: 'fa fa-clone ',
        isActive: true,
        color: 'light-grey-white',
        onClick: () => dispatch(createSessionPanel(panel.sessionId, panel.name, panel.id)),
        title: intl.formatMessage(commonMessages.clone),
        description: intl.formatMessage(analyzerMessages.clonePanelDescription),
        showTooltip: true,
      },

      {
        icon: 'fa fa-file-excel-o',
        isActive: true,
        color: 'light-grey-white',
        onClick: () => exportToExcel(),
        title: intl.formatMessage(commonMessages.export),
        description: intl.formatMessage(analyzerMessages.exportPanelDescription),
        showTooltip: true,
      },
      {
        icon: 'fa fa-trash',
        isActive: true,
        color: 'light-grey-white',
        onClick: () =>
          dispatch(
            openModal(CONFIRMATION, {
              title: analyzerMessages.deleteSessionPanelTitle,
              description: analyzerMessages.deleteSessionPanelDescription,
              done: () => {
                dispatch(closeModal(CONFIRMATION));
                dispatch(deleteSessionPanel(panel.sessionId, panel.id));
              },
            }),
          ),
        title: intl.formatMessage(commonMessages.delete),
        description: intl.formatMessage(analyzerMessages.deletePanelDescription),
        showTooltip: true,
      },
    ],
    [intl, status, dispatch, panel.sessionId, panel.id, panel.name, exportToExcel],
  );

  const anomalyExtraButtons = useMemo(
    () => [
      {
        icon: 'fa fa-clone ',
        isActive: true,
        color: 'light-grey-white',
        onClick: () => dispatch(cloneAnomalyPanel(panel.id)),
      },
      {
        icon: 'fa fa-trash',
        isActive: true,
        color: 'light-grey-white',
        onClick: () =>
          dispatch(
            openModal(CONFIRMATION, {
              title: intl.formatMessage(analyzerMessages.deleteAnomalyPanelTitle),
              description: intl.formatMessage(analyzerMessages.deleteAnomalyPanelDescription),
              done: () => {
                dispatch(closeModal(CONFIRMATION));
                dispatch(deleteAnomalyPanel(panel.id));
              },
            }),
          ),
      },
    ],
    [dispatch, intl, panel.id],
  );

  const panelButtons = panel.isPanelAnomaly ? anomalyExtraButtons : extraButtons;

  return <ExtraButtons list={panelButtons} />;
};

export const PanelActions = injectIntl(ActionButtons);

"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  mouseMove,
  mouseLeave,
  mouseDown,
  mouseUp
} from "../../../redux/modules/analyzer.sessions.panel.mouse.module";
import { ChartTooltip } from "./ChartTooltip";
import { MAX_TIME_DIFF_IN_NS } from "../../helper/signalData";
export const chartConfig = {
  margin: {
    top: 0,
    right: 30,
    left: 0,
    bottom: 2
  },
  grid: {
    strokeDasharray: "4",
    horizontal: false,
    vertical: true
  },
  styles: {
    responsiveContainer: {
      width: "100%",
      height: "100%"
    }
  }
};
export const getChartStylesContainer = (height = 250) => ({
  width: "100%",
  height: `${height}px`,
  borderTop: "1px solid #efefef"
});
export const formatTick = (value) => new Date(value / 1e6).toLocaleString("en-US", {
  hour12: false,
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit"
});
export const getTimeRange = (rangeFrom, rangeDeltaMs) => {
  const DEFAULT_RANGE_MS = 6e4;
  const fromDate = rangeFrom ? new Date(rangeFrom) : /* @__PURE__ */ new Date();
  const deltaMs = rangeDeltaMs || DEFAULT_RANGE_MS;
  const toDate = new Date(fromDate.getTime() + deltaMs);
  return { fromDate, toDate };
};
export const calculateYDomain = (data) => {
  if (data.length === 0)
    return [0, 1];
  const values = data.map((d) => d.value);
  const min = Math.min(...values);
  const max = Math.max(...values);
  const range = max - min;
  const padding = range * 0.01;
  return [Math.floor(min - padding), Math.ceil(max + padding)];
};
const MAX_DELTA_MS_TO_SHOW_MS = 1e3 * 20;
export const useXAxisProps = (fromUnixNano, toUnixNano) => {
  const rangeDeltaMs = Math.abs((toUnixNano - fromUnixNano) / 1e6);
  const showMillisecondsOnTick = rangeDeltaMs <= MAX_DELTA_MS_TO_SHOW_MS;
  const tickFormatter = useMemo(
    () => showMillisecondsOnTick ? (value) => formatTick(value) : (value) => formatTick(value),
    [showMillisecondsOnTick]
  );
  return {
    domain: [fromUnixNano, toUnixNano],
    dataKey: "systime",
    type: "number",
    interval: "preserveStartEnd",
    allowDataOverflow: true,
    tickCount: 30,
    tickFormatter,
    minTickGap: 20,
    tick: {
      fontSize: 12
    },
    hide: true
  };
};
export const yAxisProps = {
  interval: "preserveStartEnd",
  tickCount: 10,
  tick: {
    fontSize: 12
  }
};
export const useYAxisProps = (signalType) => {
  switch (signalType) {
    case "bool":
      return __spreadProps(__spreadValues({}, yAxisProps), { domain: [0, 1] });
    case "integer":
    case "double":
      return yAxisProps;
    case "string":
      return {
        tick: false,
        tickLine: false,
        axisLine: false,
        dataKey: () => 1,
        domain: [0, 2]
      };
    case "bytes":
      return {};
  }
};
const booleanToNumber = (entry) => entry.value ? 1 : 0;
export const useLineProps = (signalType, color, interpolationType) => ({
  dataKey: signalType === "bool" ? booleanToNumber : "value",
  type: interpolationType,
  stroke: color,
  fill: color,
  activeDot: { r: 5 },
  dot: { r: 1 }
});
export const useAreaProps = (signalType, color, interpolationType) => ({
  dataKey: signalType === "bool" ? booleanToNumber : "value",
  type: interpolationType,
  stroke: color,
  fill: color,
  activeDot: { r: 5 },
  dot: { r: 1 }
});
const createCursorProps = (unixNano) => ({
  stroke: "#5FAD41",
  strokeWidth: 3,
  strokeDasharray: "3",
  x: unixNano
});
export const useCursorProps = (cursorUnixNano, tool) => {
  const highlightUN = tool.type === "cursor" ? tool.highlightUnixNano : void 0;
  const cursorProps = createCursorProps(cursorUnixNano);
  const highlightProps = highlightUN === void 0 ? void 0 : __spreadProps(__spreadValues({}, createCursorProps(highlightUN)), {
    strokeOpacity: 0.5
  });
  return {
    cursorProps,
    highlightProps
  };
};
export const getNearestPreviousEntry = (dataEntries, unixNano) => _(dataEntries).filter((e) => e.systime <= unixNano).filter((e) => e.systime >= unixNano - MAX_TIME_DIFF_IN_NS).maxBy((e) => e.systime);
export const calculateCursorValue = (signalData, unixNano) => {
  const entries = signalData;
  const nearestValue = getNearestPreviousEntry(entries, unixNano);
  if (!nearestValue) {
    return { value: null, type: "noValue" };
  }
  return { value: nearestValue.value, type: "value" };
};
const calculateMouseInfo = (nextState, event) => {
  var _a;
  if (!nextState)
    return void 0;
  if ((event == null ? void 0 : event.button) !== 0)
    return void 0;
  if ("xValue" in nextState) {
    return {
      unixNano: nextState.xValue,
      pos: "center"
    };
  }
  const activeXChart = (_a = nextState.activeCoordinate) == null ? void 0 : _a.x;
  if (activeXChart === void 0)
    return void 0;
  const xChart = nextState.chartX;
  if (xChart === void 0)
    return void 0;
  const pos = xChart < activeXChart ? "left" : xChart > activeXChart ? "right" : "center";
  const unixNano = nextState == null ? void 0 : nextState.activeLabel;
  if (unixNano === void 0)
    return void 0;
  if (isNaN(unixNano))
    return void 0;
  return {
    unixNano,
    pos
  };
};
export const useMouseProps = (panelId, panelSignalId) => {
  const despatch = useDispatch();
  const onMouseDown = useCallback(
    (nextState, event) => {
      const mouseInfo = calculateMouseInfo(nextState, event);
      if (mouseInfo) {
        despatch(mouseDown(panelId, panelSignalId, mouseInfo.unixNano, mouseInfo.pos));
      }
    },
    [despatch, panelSignalId, panelId]
  );
  const onMouseMove = useCallback(
    (nextState, event) => {
      const mouseInfo = calculateMouseInfo(nextState, event);
      if (mouseInfo) {
        despatch(mouseMove(panelId, mouseInfo.unixNano, mouseInfo.pos));
      }
    },
    [panelId, despatch]
  );
  const onMouseUp = useCallback(
    (nextState, event) => {
      const mouseInfo = calculateMouseInfo(nextState, event);
      if (mouseInfo) {
        despatch(mouseUp(panelId, mouseInfo.unixNano, mouseInfo.pos));
      }
    },
    [despatch, panelId]
  );
  const onMouseLeave = useCallback(() => {
    despatch(mouseLeave(panelId));
  }, [despatch, panelId]);
  return {
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onMouseLeave
  };
};
export const calculateBitRulerBoxRange = (unixNano, signalData) => {
  const nearestPrevEntry = getNearestPreviousEntry(signalData.data, unixNano);
  if (!nearestPrevEntry)
    return { start: void 0, end: void 0 };
  const start = nearestPrevEntry.isDelta ? (
    // if the nearestPrevEntry is a delta, it is the start
    nearestPrevEntry
  ) : (
    // if the nearestPrevEntry is not a delta, we have to look for the is
    _(signalData.data).filter((e) => e.isDelta).filter((e) => e.systime < nearestPrevEntry.systime).filter((e) => e.value === nearestPrevEntry.value).maxBy((e) => e.systime)
  );
  if (!start)
    return { start: void 0, end: void 0 };
  const end = _(signalData.data).filter((e) => e.isDelta).filter((e) => e.systime > start.systime).filter((e) => e.value !== start.value).minBy((e) => e.systime);
  if (!end)
    return { start: void 0, end: void 0 };
  return { start, end };
};
export const zoomAreaProps = {
  strokeOpacity: 0.1
};
export const usePanelZoom = (panelId, tool) => {
  const isZoomToolActive = (tool == null ? void 0 : tool.type) === "zoom";
  const firstUnixNano = isZoomToolActive ? tool == null ? void 0 : tool.firstUnixNano : null;
  const secondUnixNano = isZoomToolActive ? tool == null ? void 0 : tool.secondUnixNano : null;
  return useMemo(() => {
    if (firstUnixNano == null)
      return {
        firstUnixNano: null,
        secondUnixNano: null
      };
    return {
      firstUnixNano,
      secondUnixNano
    };
  }, [firstUnixNano, secondUnixNano]);
};
export const useTooltipProps = (showTooltips) => ({
  cursor: { strokeWidth: 1 },
  isAnimationActive: true,
  animationEasing: "ease-in-out",
  animationDuration: 300,
  content: ChartTooltip,
  wrapperStyle: {
    outline: "none",
    display: showTooltips ? "block" : "none",
    zIndex: 10
  }
});

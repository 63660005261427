import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { PanelActions } from './PanelActions';
import { ToolToggleGroup } from './ToolToggleGroup';
import { TimeRangeInput } from '../../_tools/input/InputRange';
import { getPanelById, getPanelStatus } from '../../../redux/selectors/analyzer.selector';
import { updateSessionPanelPartial } from '../../../redux/modules/analyzer.sessions.panel.update.module';
import { calculateFromDate, withNewDelta, withNewFromDate } from '../../helper/panelTime';
import { formatDateWithMicrosecondsAndOffset } from '../../helper/time';
import { MIN_RANGE_DELTA_MS } from '../../../../../common/constants/analyzer';
import { DateTimeCursorInput } from '../cursorDateTimeInput/CursorDateTimeInput';

export const PanelHeader: React.FC<{ panelId: number }> = React.memo(({ panelId }) => {
  const dispatch = useDispatch();
  const panel = useSelector((state) => getPanelById(state, panelId));
  const panelStatus = useSelector((state) => getPanelStatus(state, panelId));

  const handleDateChange = debounce((newDate: Date) => {
    const rangeFrom = formatDateWithMicrosecondsAndOffset(newDate);
    const time = withNewFromDate(panel.time, newDate);
    dispatch(
      updateSessionPanelPartial(panelId, {
        rangeFrom: rangeFrom,
        time,
      }),
    );
  }, 300);

  const getSelectedDate = () => {
    if (panel && panel.rangeFrom) {
      return new Date(panel.rangeFrom);
    }
    return new Date();
  };

  const handleRangeChange = (newRange: number) => {
    const time = withNewDelta(panel.time, newRange);
    const calculatedFromDate = calculateFromDate(time);

    dispatch(
      updateSessionPanelPartial(panelId, {
        rangeFrom: formatDateWithMicrosecondsAndOffset(calculatedFromDate),
        rangeDeltaMs: newRange,
        time,
      }),
    );
  };

  const handelToggleTool = (panelTool) => {
    dispatch(
      updateSessionPanelPartial(panelId, {
        tool: panelTool,
      }),
    );
  };
  const valueDate = getSelectedDate();

  return (
    <>
      <div>{panel.name}</div>
      <div className='action-panel-session'>
        <ToolToggleGroup panel={panel} onChange={handelToggleTool} />
        <PanelActions panel={panel} status={panelStatus}></PanelActions>
        <div className='cursor-time-container'>
          <DateTimeCursorInput value={valueDate} onChange={handleDateChange} />
        </div>
        <TimeRangeInput
          initialValue={panel.rangeDeltaMs || MIN_RANGE_DELTA_MS}
          onChange={handleRangeChange}
        />
      </div>
    </>
  );
});

import { isEmpty, isUndefined } from 'lodash';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Todo, TodoFunction } from '../../../../../../common/types/common';
import {
  DATA_POINT_ARRAY_COMMON_FIELDS,
  DATA_POINT_COMMON_FIELDS,
  DATA_POINT_INDEX_COMMON_FIELDS,
  dataPointArrayCommonSchema,
  dataPointCommonSchema,
  dataPointIndexCommonSchema,
} from '../../../../core/common/dataPoint.constants';
import { FIRST_FIELDS_ORDER } from '../../../../core/common/datatron';
import labels from '../../../../messages/dynamicField.messages';
import ErrorMessages from '../../../../messages/errors.messages';
import {
  ARRAY_DATAPOINT_FORM_TYPE,
  ARRAY_INDEX_DATAPOINT_FORM_TYPE,
  SCALING_FACTOR_FIELD,
  SIMPLE_DATAPOINT_FORM_TYPE,
} from '../../../../redux/constants/dataPoint.form.constants';
import {
  DP_ADD_DATA_POINT,
  DP_EDIT_DATA_POINT,
} from '../../../../redux/constants/modals.constants';
import {
  createChildDataPoint,
  createDataPoint,
} from '../../../../redux/modules/datatron.devices.dataPoints.create.module';
import { updateDataPoint } from '../../../../redux/modules/datatron.devices.dataPoints.update.module';
import {
  getDataPointByIdAndDeviceId,
  selectDatatronError,
} from '../../../../redux/selectors/datatron.selector';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { selector } from '../../../../redux/selectors/util';
import { ArrayIndexForm } from '../dataPointArrayIndex/ArrayIndexForm';
import { DataPointFormField } from './DataPointFormField';
import { ArrayFormPage } from './arrayPage/ArrayFormPage';
import { indicesToString, isValidJSON } from './helpers';
import { DatapointSchema, DatapointVariant, Field, PropertiesObjectField } from './schema';
import { Exception } from 'sass';

type FormType = 'simple' | 'array' | 'index';

type UseFormHookState = Todo;

type UseFormHookArgs = {
  schema: UseFormHookState;
  formType: FormType | undefined;
  isEditForm: boolean;
};

type UseDataPointForm = (args: UseFormHookArgs) => {
  state;
  makeStandardFields: TodoFunction;
  makeSpecialFields: TodoFunction;
  handleSubmit: TodoFunction;
  isSubmitDisabled: boolean;
  parentDataPoint: Todo;
};

type FieldWithExtraInfo = Field & {
  isRequired: boolean;
  key: string;
  value: string;
  type: string;
  additionalProperties?: boolean;
  properties?: any;
  required?: string[];
};

/**
 * Adapt the fields defined by the data point schema into the format for the form state.
 */
export const consolidateFieldInformation = (
  schema: PropertiesObjectField | undefined,
  initialValues: Record<string, string>,
) => {
  if (isEmpty(schema)) return {};

  const { properties, required } = schema;
  return Object.entries(properties).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]: {
        ...value,
        isRequired: required?.includes(key) ?? false,
        key: key,
        value: initialValues[key] ?? '',
      },
    }),
    {} as Record<string, FieldWithExtraInfo>,
  );
};

export const getDefinitionKeyByFormType = (formType: string): DatapointVariant => {
  switch (formType) {
    case 'simple':
      return SIMPLE_DATAPOINT_FORM_TYPE;
    case 'array':
      return ARRAY_DATAPOINT_FORM_TYPE;
    case 'index':
      return ARRAY_INDEX_DATAPOINT_FORM_TYPE;
    default:
      return SIMPLE_DATAPOINT_FORM_TYPE;
  }
};

/**
 * Convert the schema into the format required for the React state
 */
export const getConfigFieldsFromSchema = (
  schema: DatapointSchema,
  formType: string,
  initialValues: Record<string, string> = {},
) =>
  consolidateFieldInformation(
    schema.definitions[getDefinitionKeyByFormType(formType)],
    initialValues,
  );

/** Used with a list.reduce() to turn common fields into the format required for the React state. */
const fieldReducer = (initialValues, requiredFields: string[]) => (prev, current) => {
  if (current.hidden) return prev;
  return {
    ...prev,
    [current._key]: {
      type: current.type,
      key: current._key,
      isRequired: requiredFields.includes(current._key),
      value: initialValues[current._key] ?? '',
      readOnly: current.readOnly,
    },
  };
};

/**
 * Define the fields that every form of a given formType should include.
 */
const getCommonFields = (formType: string, initialValues) => {
  switch (formType) {
    case 'simple':
      return DATA_POINT_COMMON_FIELDS.reduce(
        fieldReducer(initialValues, dataPointCommonSchema.required),
        {},
      );
    case 'array':
      return DATA_POINT_ARRAY_COMMON_FIELDS.reduce(
        fieldReducer(initialValues, dataPointArrayCommonSchema.required),
        {},
      );
    case 'index':
      return DATA_POINT_INDEX_COMMON_FIELDS.reduce(
        fieldReducer(initialValues, dataPointIndexCommonSchema.required),
        {},
      );
    default:
      return {};
  }
};

function getNestedValue(obj, keyPath) {
  // Split the path into an array of keys
  const keys = keyPath.split('.');
  // Reduce over the keys to delve into the nested object structure
  return keys.reduce(
    (currentObj, key) =>
      // If the current value is undefined, immediately return undefined
      // Otherwise, continue to the next key level
      currentObj ? currentObj[key] : undefined,
    obj,
  );
}

// Helper function to create or merge nested objects
const setNestedValue = (obj: any, keys: string[], value: any) => {
  const [firstKey, ...remainingKeys] = keys;

  if (remainingKeys.length === 0) {
    return {
      ...obj,
      [firstKey]: value,
    };
  }

  return {
    ...obj,
    [firstKey]: setNestedValue(obj[firstKey] || {}, remainingKeys, value),
  };
};

/**
 * Get the default form value for a field
 */
const getDefaultValues = (item, dataPoint) => {
  // if a value already exists for the field, use that
  const previousValue = dataPoint
    ? getNestedValue(dataPoint.config, item.key) ?? getNestedValue(dataPoint, item.key)
    : undefined;
  if (!isUndefined(previousValue)) return previousValue;

  // if the field has a defined default value, use that
  if (!isUndefined(item.default)) return item.default;

  // use true if boolean, 0 if a number, or empty string otherwise
  if (item.type === 'boolean') return true;
  if (item.type === 'number' && item.key === SCALING_FACTOR_FIELD) return 1;
  if (item.type === 'number') return 0;
  return '';
};

const useParent = (
  deviceId: string,
  parentDataPointId: string | undefined,
  dataPoint: Todo | null,
) => {
  const parentFromState = useSelector((state) =>
    getDataPointByIdAndDeviceId(state, deviceId, parentDataPointId),
  );
  return parentFromState ? parentFromState : dataPoint?.parent;
};

const getFormType = (dataPoint, initialFormType: FormType | undefined): FormType => {
  if (initialFormType) {
    return initialFormType;
  }
  if (dataPoint?.config?.arrayIndex && dataPoint?.config?.arrayIndex?.length !== 0) {
    return 'index';
  }
  if (dataPoint?.config?.arrayDimensions) {
    return 'array';
  }
  return 'simple';
};

const useIsSubmitDisabled = (errorState: Record<string, boolean>): boolean => {
  // Create a React state element that combines the error state into a single boolean
  const [isSubmitDisabled, isSubmitDisabledSet] = useState(false);

  // any time errorState is updated, check if submission should be disabled
  useEffect(() => {
    // use some() to short circuit the logic. If any errors at all are found, submission should be disabled
    const hasError = Object.values(errorState).some((v) => v);
    // only dispatch the state update if it changes
    isSubmitDisabledSet(hasError);
  }, [errorState]);
  return isSubmitDisabled;
};

interface UseDataPointFormArgs {
  schema: Todo;
  formType: Todo;
  isEditForm: boolean;
}

/** defined which fields require special handling and in which order they should appear */
const SPECIAL_FIELD_NAMES = ['comment', 'arrayDimensions', 'arrayIndex', 'custom'] as const;

type SpecialFieldName = (typeof SPECIAL_FIELD_NAMES)[number];

const isSpecialFieldName = (name: string): name is SpecialFieldName =>
  SPECIAL_FIELD_NAMES.includes(name as SpecialFieldName);

/** A selector that gets the payload of the edit-data-point-modal from the state */
const editDatapointModalPayloadSelector = selector((state) =>
  getModalPayload(state, DP_EDIT_DATA_POINT),
);

/** A selector that gets the payload of the add-data-point-modal from the state */
const addDatapointModalPayloadSelector = selector((state) =>
  getModalPayload(state, DP_ADD_DATA_POINT),
);

/**
 * Custom react form that defines all the form logic and handles most of it
 */
export const useDatapointForm: UseDataPointForm = ({
  schema,
  formType,
  isEditForm,
}: UseDataPointFormArgs) => {
  // get payload based on modal type
  const {
    deviceId,
    dataPointId = 0,
    parentDataPointId,
    isIndexDataPoint,
  } = useSelector(
    isEditForm ? editDatapointModalPayloadSelector : addDatapointModalPayloadSelector,
  );

  // TODO: use this to pin down the exact type of schema
  // parseAndLog(zDatapointSchema, schema, 'schema');

  // get the data point and if it has a parent, the parent as well
  const dataPoint = useSelector((state) =>
    getDataPointByIdAndDeviceId(state, deviceId, dataPointId),
  );

  const parentDataPoint = useParent(deviceId, parentDataPointId, dataPoint);
  const parentId = parentDataPoint?.id;

  // if formType is undefined, deduce what type of form it is
  formType = getFormType(dataPoint, formType);

  // generate static and dynamic fields, then combine them into a single object.
  const commonFields = getCommonFields(formType, dataPoint || {});
  const configFields = getConfigFieldsFromSchema(schema, formType, dataPoint?.config || {});
  const allFields: typeof configFields = { ...commonFields, ...configFields };

  const { formatMessage } = useIntl();

  // If submission fails, errors go here
  const formError = useSelector(selectDatatronError);

  type FieldWithMoreExtraInfo = FieldWithExtraInfo & {
    label: string;
    fieldType: 'standard' | 'special';
  };
  type Fields = {
    standard: FieldWithMoreExtraInfo[];
    special: FieldWithMoreExtraInfo[];
  };

  /**
   * Recursively process the custom fields in the data point schema.
   * - Extract the key, label, value, parent, and isRequired properties of each field.
   * - If the field is an object, recursively call this function on its properties.
   * - If the field is a simple property, return it as a single object.
   * @param properties the properties object to process
   * @param parentKey the parent key of the current properties object
   * @param isRequired whether the parent object is required
   * @returns an array of processed fields
   */
  const processCustomFields = (
    properties: Record<string, Todo>,
    parentKey: string[] = ['custom'],
    isRequired: boolean = false,
  ): Todo[] =>
    Object.entries(properties).flatMap(([propertyKey, propertyValue]) => {
      const currentKey = [...parentKey, propertyKey];

      if (propertyValue.type === 'object' && propertyValue.properties) {
        return processCustomFields(propertyValue.properties, currentKey, isRequired);
      }

      return {
        ...propertyValue,
        key: currentKey.join('.'),
        parent: parentKey,
        isRequired,
        value: propertyValue.value,
        label: labels[propertyKey] ? formatMessage(labels[propertyKey]) : propertyKey,
        fieldType: 'standard',
      };
    });

  /**
   * Reduce the fields into 2 lists: standard fields, which are 1/4 of the modal wide and require no special handling,
   * and special fields, which are full width and require special handling.
   */
  const fields = Object.entries(allFields).reduce(
    (prev, current): Fields => {
      const [currentKey, currentValue] = current;
      // if a field is custom, with nested props
      if (currentKey === 'custom' && isUndefined(currentValue.additionalProperties)) {
        const customFields = processCustomFields(
          currentValue.properties ?? {},
          ['custom'],
          currentValue.isRequired,
        );
        return {
          ...prev,
          standard: [...prev.standard, ...customFields],
        };
      }
      // if a field is special, add it to the special array
      if (isSpecialFieldName(currentKey))
        return {
          ...prev,
          special: [
            ...prev.special,
            {
              ...currentValue,
              label: labels[currentValue.key]
                ? formatMessage(labels[currentValue.key])
                : currentValue.key,
              fieldType: 'special',
            },
          ],
        };

      // otherwise add it to the standard array
      return {
        ...prev,
        standard: [
          ...prev.standard,
          {
            ...currentValue,
            label: labels[currentValue.key]
              ? formatMessage(labels[currentValue.key])
              : currentValue.key,
            fieldType: 'standard',
          },
        ],
      };
    },
    { special: [], standard: [] } as Fields,
  );
  /**
   * Keep track of which fields have errors in a simpler state, of format `fieldName: boolean`.
   * The default state takes the list of all fields and creates an object with all fields as key, all set to false
   */
  const [errorState, errorStateSet] = useState<Record<string, boolean>>(
    Object.keys(allFields).reduce(
      (prev, current) => ({
        ...prev,
        [current]: false,
      }),
      {},
    ),
  );

  type State = Fields;
  type Action = {
    fieldType: 'standard' | 'special';
    fieldName: string;
    newValue?: any;
    error?: string;
  };

  /**
   * The reducer used by useReducer, which updates the field defined by fieldType ('standard' | 'special') and
   * fieldName (which comes from the config) with the new value and error passed.
   */
  const reducer = (state: State, { fieldType, fieldName, newValue, error }: Action): State => {
    errorStateSet({ ...errorState, [fieldName]: !!error }); // TODO: reducer should be pure
    return {
      ...state,
      [fieldType]: state[fieldType].map((currentField) => {
        // If the current field has not been changed, just return it unchanged
        if (currentField.key !== fieldName) return currentField;

        // If the current field is an object field and has additional properties,
        // we remove the error from any subfields that have been updated.
        // Otherwise, we parse as JSON.
        if (currentField.type === 'object' && !currentField.additionalProperties) {
          if (currentField.additionalProperties) {
            let objectParseErrMessage: string | null = null;
            // Validate JSON.
            try {
              JSON.parse(newValue);
            } catch (e) {
              objectParseErrMessage = (e as Exception).message;
            }
            return {
              ...currentField,
              value: newValue,
              error: objectParseErrMessage,
            };
          }
          // It has no additional properties.
          return {
            ...currentField,
            oneOf: currentField.oneOf.map((oneOf) => ({
              ...oneOf,
              properties: Object.keys(oneOf.properties).reduce(
                (prev, current) => ({
                  ...prev,
                  [current]: {
                    ...oneOf.properties[current],
                    isRequired: oneOf.required && oneOf.required.includes(current),
                    // If this property has a different value in the new value from the previous value, set error to undefined
                    // otherwise keep the error
                    error:
                      newValue[current] === currentField.value[current]
                        ? oneOf.properties[current].error
                        : undefined,
                  },
                }),
                {},
              ),
            })),
            value: newValue,
            error,
          };
        }
        // in any other case, simply update the value to newValue and set the error to the passed error
        return {
          ...currentField,
          value: newValue,
          error,
        };
      }),
    };
  };

  const reduxDispatch = useDispatch();

  /**
   * Sort the standard fields, define and populate their default values
   */
  const standardFieldDefinitions = fields.standard
    .sort((a, b) => {
      const firstIndex = FIRST_FIELDS_ORDER.indexOf(a.key);
      const secondIndex = FIRST_FIELDS_ORDER.indexOf(b.key);
      return (firstIndex < 0 ? 200 : firstIndex) - (secondIndex < 0 ? 200 : secondIndex);
    })
    .map((item) => ({
      ...item,
      value: getDefaultValues(item, dataPoint),
    }));

  /**
   * Sort the special fields
   */
  const specialFieldDefinitions = fields.special
    .sort((a, b) => {
      const firstIndex = SPECIAL_FIELD_NAMES.indexOf(a.key as SpecialFieldName);
      const secondIndex = SPECIAL_FIELD_NAMES.indexOf(b.key as SpecialFieldName);
      return (firstIndex < 0 ? 200 : firstIndex) - (secondIndex < 0 ? 200 : secondIndex);
    })
    .map((item) => {
      // Set default value for custom configurations (expected as JSON).
      if (
        item.type === 'object' &&
        item.key === 'custom' &&
        (item.value === '' || item.value === undefined || item.value === null)
      ) {
        return {
          ...item,
          value: {},
        };
      }
      return item;
    });

  /**
   * Define a React state with custom reducer logic that keeps track of the form
   */
  const [formState, dispatch] = useReducer(reducer, {
    standard: standardFieldDefinitions,
    special: specialFieldDefinitions,
  });

  /**
   * Set errors in the errorState when the Redux state updates its error field. This field is populated when
   * submission fails.
   */
  // TODO: fix me, avoid suppressing the linter, this formhook is to complex need refactoring or applying new approach
  useEffect(() => {
    // combine the special and standard lists to more simply iterate over the entire state
    [...formState.special, ...formState.standard].forEach((field) => {
      // check if this field has an error set in Redux
      const errorId: string | undefined =
        formError[Object.keys(formError).find((errorKey) => errorKey.includes(field.key)) || ''];
      // if there is an error set, get the translation of the error message
      // The config repo returns errors.fields.<errorName>, so trim out just the error name as the key in the error message definitions
      const error =
        errorId == 'errors.field.required'
          ? formatMessage(ErrorMessages[errorId.substring(errorId.lastIndexOf('.') + 1)])
          : errorId;

      // If there is an error, put it in the main field state to be displayed on that field's input element
      // also update the fields entry in errorState, which is for enabling/disabling the submit button
      if (!isUndefined(error)) {
        /**
         * Ensure errors are displayed on dynamic fields too
         */
        if (field.type === 'object' && !field.additionalProperties) {
          field.oneOf.forEach((oneOf) => {
            Object.keys(oneOf.properties).forEach((property) => {
              // check if this sub-property should have an error
              const childError =
                formError[
                  Object.keys(formError).find((errorKey) => errorKey.includes(property)) || ''
                ];
              if (childError) oneOf.properties[property].error = error;
            });
          });
          if (field.value !== '') {
            // update the state to cause a rerender with the errors displayed
            errorStateSet({ ...errorState, [field.key]: true });
            dispatch({
              fieldType: field.fieldType,
              fieldName: field.key,
              newValue: field.value,
              error: undefined,
            });
            return;
          }
        }

        errorStateSet({ ...errorState, [field.key]: true });
        dispatch({
          fieldType: field.fieldType,
          fieldName: field.key,
          newValue: field.value,
          error,
        });
      }
    });
    // note: the dependency array intentionally only lists formError, because adding the others can cause infinite reloading
  }, [formError, formatMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = useCallback(
    (fieldType, fieldName: string, newValue, error = undefined) =>
      dispatch({ fieldType, fieldName, newValue, error }),
    [dispatch],
  );

  // Turn an object defining a single standard field into a JSX element
  const makeStandardFields = useCallback(
    (field) => (
      <DataPointFormField
        key={field.key}
        onChange={(newValue, error = undefined) => onChange('standard', field.key, newValue, error)}
        field={field}
      />
    ),
    [onChange],
  );

  /**
   * Turn the elements defining special fields into JSX elements
   */
  const makeSpecialFields = (field) => {
    // commend needs a textarea input
    if (field.key === 'comment')
      return (
        <label key={field.key}>
          <>
            {field.label}
            <textarea
              onChange={(e) => onChange('special', 'comment', e.target.value)}
              value={field.value}
            />
          </>
        </label>
      );
    // arrayDimensions need the array form
    if (field.key === 'arrayDimensions') {
      const type = formState.standard.find((standard) => standard.key === 'type')?.value;
      return (
        <ArrayFormPage
          key={field.key}
          type={type}
          field={field}
          updateHandler={(newValue, error = undefined) =>
            onChange('special', 'arrayDimensions', newValue, error)
          }
        />
      );
    }
    // arrayIndex requires the array indices
    if (field.key === 'arrayIndex') {
      const type =
        formState.standard.find((standard) => standard.key === 'type')?.value ||
        parentDataPoint?.config?.type ||
        parentDataPoint?.config?.dataType?.name;
      const dimensions = parentDataPoint?.config?.arrayDimensions ?? [];
      const label = dataPoint?.parent?.label || parentDataPoint?.label;

      return (
        <ArrayIndexForm
          key={field.key}
          dimensions={dimensions}
          type={type}
          arrayIndex={field.value}
          indicesParentSet={(newValue, error) => {
            onChange('special', 'arrayIndex', newValue, error);
            // also update the label with every change to the index, since the label is generated from the index
            onChange('standard', 'label', label + indicesToString(newValue));
          }}
        />
      );
    }
    // Custom configuration with additional properties for regular JSON input. On submission, the value will then be parsed as JSON.
    if (field.key === 'custom' && field.additionalProperties)
      return (
        <label key={field.key}>
          <>
            {field.label}
            <textarea
              onChange={(e) =>
                onChange(
                  'special',
                  'custom',
                  e.target.value,
                  isValidJSON(e.target.value) ? undefined : 'errors.field.invalid_json',
                )
              }
              value={
                typeof field.value === 'object' ? JSON.stringify(field.value, null, 2) : field.value
              }
            />
          </>
        </label>
      );
  };

  /**
   * Handle submission logic
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    /**
     * Reduce all the field elements into a simple `{fieldName: value}` object
     */
    const body = [...formState.standard, ...formState.special].reduce((prev, current) => {
      let value: string | number | object = current.value;
      // If this field has a numeric type, ensure it is submitted as a number and not a string
      if (current.type === 'number' || current.type === 'integer') {
        value = parseInt(current.value, 10);
      }
      // If this field is an object with additional properties, we parse it as JSON object.
      // This is used, for example, with custom configurations.
      if (current.type === 'object' && current.additionalProperties && typeof value == 'string') {
        value = JSON.parse(value);
      }
      // empty string is technically a valid value for some fields, so if a field has an empty string, set it to undefined
      const valueOrUndefined = value === '' ? undefined : value;
      // common fields go at the top level of the item
      if (DATA_POINT_COMMON_FIELDS.some((fieldName) => fieldName._key === current.key))
        return {
          ...prev,
          [current.key]: valueOrUndefined,
        };
      // all the fields that aren't common fields go into the `config` object

      if (current.key.includes('.')) {
        const keys = current.key.split('.');
        return {
          ...prev,
          config: setNestedValue(prev.config || {}, keys, valueOrUndefined),
        };
      } else {
        return {
          ...prev,
          config: {
            ...prev.config,
            [current.key]: valueOrUndefined,
          },
        };
      }
    }, {});
    // Since there are three different redux reducers for submission, check which one is needed then call it
    if (isEditForm)
      reduxDispatch(
        updateDataPoint(deviceId, dataPointId, {
          ...body,
          parentId,
        }),
      );
    else if (isIndexDataPoint)
      reduxDispatch(
        createChildDataPoint(deviceId, {
          ...body,
          parentId,
        }),
      );
    else reduxDispatch(createDataPoint(deviceId, body));
  };

  const isSubmitDisabled = useIsSubmitDisabled(errorState);

  return {
    state: formState,
    makeStandardFields,
    makeSpecialFields,
    handleSubmit,
    isSubmitDisabled,
    parentDataPoint,
  };
};

"use strict";
import { defineMessages } from "react-intl";
export default defineMessages({
  server_error: {
    id: "notifications.title.server_error",
    defaultMessage: "Server Error"
  },
  success_title: {
    id: "notifications.title.success",
    defaultMessage: " Success"
  },
  record_updated_success: {
    id: "notifications.title.record.success_update",
    defaultMessage: "Record successfully updated"
  },
  record_created_success: {
    id: "notifications.title.record.success_create",
    defaultMessage: "Record successfully created"
  },
  something_happened: {
    id: "notifications.description.server_error",
    defaultMessage: "Something bad has happened. Error: {error}"
  },
  new_machine_created_title: {
    id: "notifications.title.machine.created",
    defaultMessage: "{machineName} has been created"
  },
  machine_updated_title: {
    id: "notifications.title.machine.updated",
    defaultMessage: "Machine has been updated"
  },
  new_datatron_created_title: {
    id: "notifications.title.datatron.created",
    defaultMessage: "{datatronName} has been created"
  },
  datatron_updated_title: {
    id: "notifications.title.datatron.updated",
    defaultMessage: "Datatron has been updated"
  },
  new_device_created_title: {
    id: "notifications.title.new_device.created",
    defaultMessage: "New Device has been created"
  },
  new_device_created_description: {
    id: "notifications.description.new_device.created",
    defaultMessage: "Now you can use your new Device!"
  },
  datapoint_archived_title: {
    id: "notifications.title.dataPoint.archived",
    defaultMessage: "Data Point has been archived successfully"
  },
  datapoint_unarchive_success_title: {
    id: "notifications.title.dataPoint.unarchive.success",
    defaultMessage: "Data point has been unarchived successfully"
  },
  datapoint_unarchive_failed_title: {
    id: "notifications.title.dataPoint.unarchive.failed",
    defaultMessage: "Failed to unarchive data point"
  },
  successfully_unarchived_description: {
    id: "notifications.description.dataPoint.unarchive.success",
    defaultMessage: "Successfully unarchived"
  },
  failed_to_unarchive_description: {
    id: "notifications.description.dataPoint.unarchive.failed",
    defaultMessage: "Failed to unarchive: {errorMsg}"
  },
  device_updated_title: {
    id: "notifications.title.device.updated",
    defaultMessage: "Device has been updated"
  },
  device_archived_title: {
    id: "notifications.title.device.archived",
    defaultMessage: "Device has been archived successfully"
  },
  failed_to_persist_hidden_event_title: {
    id: "notifications.title.maintenance_events.failed_to_persist_hidden_event",
    defaultMessage: "Failed to persist hidden event!"
  },
  failed_to_persist_hidden_event_description: {
    id: "notifications.description.maintenance_events.failed_to_persist_hidden_event",
    defaultMessage: "An error occurred when tried to persist hidden event in the browser storage!"
  },
  notifications_title_profile: {
    id: "notifications.title.profile",
    defaultMessage: "Profile"
  },
  notifications_description_profile_your_password_has_been_changed: {
    id: "notifications.description.profile.your password_has_been_changed_successfully",
    defaultMessage: "Your password has been changed successfully."
  },
  notifications_description_profile_your_location_has_been_changed: {
    id: "notifications.description.profile.your_location_has_been_changed_successfully",
    defaultMessage: "Your location has been changed successfully."
  },
  profile_your_locale_has_been_changed: {
    id: "notifications.description.profile.locale_has_been_changed_successfully",
    defaultMessage: "Your locale has been changed successfully."
  },
  device_publish_success_title: {
    id: "notifications.title.device.publish.success",
    defaultMessage: "Device has been published successfully"
  },
  device_generic_success: {
    id: "notifications.title.device.generic.success",
    defaultMessage: "Command has been successfully submitted"
  },
  device_publish_failed_title: {
    id: "notifications.title.device.publish.failed",
    defaultMessage: "Failed to publish device"
  },
  device_generic_failed: {
    id: "notifications.title.device.generic.failed",
    defaultMessage: "Failed to submit command"
  },
  device_import_success_title: {
    id: "notifications.title.device.import.success",
    defaultMessage: "Device import request has been successfully submitted"
  },
  device_import_failed_title: {
    id: "notifications.title.device.import.failed",
    defaultMessage: "Failed to send device import request"
  },
  device_activation_success_title: {
    id: "notifications.title.device.activation.success",
    defaultMessage: "Device was activated successfully"
  },
  device_activation_failed_title: {
    id: "notifications.title.device.activation.failed",
    defaultMessage: "Failed to activate device"
  },
  device_deactivation_success_title: {
    id: "notifications.title.device.deactivation.success",
    defaultMessage: "Device was deactivated successfully"
  },
  device_deactivation_failed_title: {
    id: "notifications.title.device.deactivation.failed",
    defaultMessage: "Failed to deactivate device"
  },
  device_command_success_title: {
    id: "notifications.title.device.command.success",
    defaultMessage: "Command History "
  },
  device_command_failed_title: {
    id: "notifications.title.device.command.failed",
    defaultMessage: "Failed to get command history"
  },
  successfully_changed_event: {
    id: "notifications.title.event",
    defaultMessage: "Event"
  },
  successfully_changed_event_description: {
    id: "notifications.description.event.changed",
    defaultMessage: "Successfully changed"
  },
  successfully_added_event_description: {
    id: "notifications.description.event.added",
    defaultMessage: "Successfully added"
  },
  failed_to_update_event_description: {
    id: "notifications.description.event.changed.error",
    defaultMessage: "Failed to update: {errorMsg}"
  },
  failed_to_add_event_description: {
    id: "notifications.description.event.added.error",
    defaultMessage: "Failed to add: {errorMsg}"
  },
  user_added: {
    id: "notifications.title.admin.user_added",
    defaultMessage: "User {name} successfully added"
  },
  user_updated: {
    id: "notifications.title.admin.user_updated",
    defaultMessage: "User {name} successfully updated"
  },
  user_removed: {
    id: "notifications.title.admin.user_removed",
    defaultMessage: "User {name} successfully removed"
  },
  location_added: {
    id: "notifications.title.admin.location_added",
    defaultMessage: "Location {name} successfully added"
  },
  location_updated: {
    id: "notifications.title.admin.location_updated",
    defaultMessage: "Location {name} successfully updated"
  },
  location_removed: {
    id: "notifications.title.admin.location_removed",
    defaultMessage: "Location {name} successfully removed"
  },
  organization_added: {
    id: "notifications.title.admin.organization_added",
    defaultMessage: "Organization {name} successfully added"
  },
  organization_updated: {
    id: "notifications.title.admin.organization_updated",
    defaultMessage: "Organization {name} successfully updated"
  },
  organization_removed: {
    id: "notifications.title.admin.organization_removed",
    defaultMessage: "Organization {name} successfully removed"
  },
  account_added: {
    id: "notifications.title.admin.account_added",
    defaultMessage: "Account {name} successfully added"
  },
  account_updated: {
    id: "notifications.title.admin.account_updated",
    defaultMessage: "Account {name} successfully updated"
  },
  role_updated: {
    id: "notifications.title.admin.role_updated",
    defaultMessage: "Role {name} successfully updated"
  },
  calendar_created: {
    id: "notifications.title.calendar_created",
    defaultMessage: "Calendar {name} successfully created"
  },
  calendar_renamed: {
    id: "notifications.title.calendar_renamed",
    defaultMessage: "Calendar successfully renamed to {name}"
  },
  calendar_removed: {
    id: "notifications.title.calendar_removed",
    defaultMessage: "Calendar {name} successfully removed"
  },
  download_success: {
    id: "notifications.download.success",
    defaultMessage: "File downloaded"
  },
  download_failed: {
    id: "notifications.download.failed",
    defaultMessage: "Cannot download file"
  },
  experiment_added: {
    id: "notifications.title.experimentsPage.experiment_added",
    defaultMessage: "Experiment {name} successfully added"
  },
  experiment_updated: {
    id: "notifications.title.experimentsPage.experiment_updated",
    defaultMessage: "Experiment {name} successfully updated"
  },
  experiment_archived: {
    id: "notifications.title.experimentsPage.experiment_archived",
    defaultMessage: "Experiment {name} successfully archived"
  },
  measurement_archived: {
    id: "notifications.title.experimentsPage.measurement_archived",
    defaultMessage: "Measurement successfully archived"
  },
  measurement_merge: {
    id: "notifications.title.experimentsPage.measurement_merged",
    defaultMessage: "Measurement successfully merged"
  },
  sync_to_altasigma_success: {
    id: "notifications.machinePage.sync_to_altasigma.success",
    defaultMessage: "Sync to AltaSigma completed"
  },
  sync_to_altasigma_failed: {
    id: "notifications.machinePage.sync_to_altasigma.failed",
    defaultMessage: "Sync to AltaSigma failed"
  },
  sync_to_altasigma_failed_description: {
    id: "notifications.machinePage.sync_to_altasigma.failed.description",
    defaultMessage: "You have to setup following DataPoints for this Machine:"
  },
  copy_link_success: {
    id: "notifications.copy_link.success",
    defaultMessage: "Link copied"
  },
  copy_link_failed: {
    id: "notifications.copy_link.failed",
    defaultMessage: "Failed to copy link"
  },
  unsupported_signal: {
    id: "notifications.unsupported_signal",
    defaultMessage: "Unsupported signal type"
  },
  session_updated_description: {
    id: "notifications.description.session.updated",
    defaultMessage: "Session successfully updated"
  },
  session_panel_created_description: {
    id: "notifications.description.session.panel.created",
    defaultMessage: "Panel successfully created"
  },
  session_panel_updated_description: {
    id: "notifications.description.session.panel.updated",
    defaultMessage: "Panel successfully updated"
  },
  session_panel_removed_description: {
    id: "notifications.description.session.panel.removed",
    defaultMessage: "Panel successfully removed"
  }
});

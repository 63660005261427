"use strict";
import {
  ROLES_PERMISSIONS,
  CALENDAR_PERMISSIONS,
  USERS_PERMISSIONS,
  ACCOUNTS_PERMISSIONS,
  LOCATIONS_PERMISSIONS,
  MACHINES_PERMISSIONS,
  EVENTS_PERMISSIONS,
  REPORTS_PERMISSIONS,
  PRODUCTS_PERMISSIONS,
  DATATRONS_PERMISSIONS,
  ORGANIZATIONS_PERMISSIONS,
  TOOLS_PERMISSIONS,
  EXPERIMENTS_PERMISSIONS,
  ANALYZER_PERMISSIONS
} from "../constants/permissions";
import RolesPermissions from "./roles.permissions";
import CalendarPermissions from "./calendar.permissions";
import UsersPermissions from "./users.permissions";
import AccountsPermissions from "./accounts.permissions";
import LocationsPermissions from "./locations.permissions";
import MachinesPermissions from "./machines.permissions";
import EventsPermissions from "./events.permissions";
import ReportsPermissions from "./reports.permissions";
import ProductsPermissions from "./products.permissions";
import DatatronsPermissions from "./datatrons.permissions";
import OrganizationsPermissions from "./organizations.permissions";
import ToolsPermissions from "./tools.permissions";
import ExperimentsPermissions from "./experiments.permissions";
import AnalyzerPermissions from "./analyzer.permissions";
const permissionRecord = {
  [ROLES_PERMISSIONS]: RolesPermissions,
  [CALENDAR_PERMISSIONS]: CalendarPermissions,
  [USERS_PERMISSIONS]: UsersPermissions,
  [ACCOUNTS_PERMISSIONS]: AccountsPermissions,
  [LOCATIONS_PERMISSIONS]: LocationsPermissions,
  [MACHINES_PERMISSIONS]: MachinesPermissions,
  [EVENTS_PERMISSIONS]: EventsPermissions,
  [REPORTS_PERMISSIONS]: ReportsPermissions,
  [PRODUCTS_PERMISSIONS]: ProductsPermissions,
  [DATATRONS_PERMISSIONS]: DatatronsPermissions,
  [ORGANIZATIONS_PERMISSIONS]: OrganizationsPermissions,
  [TOOLS_PERMISSIONS]: ToolsPermissions,
  [EXPERIMENTS_PERMISSIONS]: ExperimentsPermissions,
  [ANALYZER_PERMISSIONS]: AnalyzerPermissions
};
export default permissionRecord;
